import React, {useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import axiosInstance from "../../helpers/AxiosHelper";
import NomenclatorFurnizor from "./NomenclatorFurnizor";
import NomenclatorMediator from "./NomenclatorMediator";
import NomenclatorObservator from "./NomenclatorObservator";

const Nomenclatoare = () => {


    return (
        <React.Fragment>
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Card>
                                <CardBody>
                                    <NomenclatorFurnizor/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={6}>
                            <Card>
                                <CardBody>
                                    <NomenclatorMediator/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={6} className="mt-4">
                            <Card>
                                <CardBody>
                                    <NomenclatorObservator/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Nomenclatoare;
