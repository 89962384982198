import React, {useEffect, useState} from 'react';
import CandidatiBar from "./CandidatiBar";
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import axiosInstance from "../../helpers/AxiosHelper";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import DatePicker from "react-datepicker";
import TableComponent from "../Cursuri/TableComponent";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import FileDownload from "js-file-download";

const Mediere = () => {

    const [atomId, setAtomId] = useAtom(idAtom);
    const [candidate, setCandidate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mediationSessions, setMediationSessions] = useState([]);
    const [mediators, setMediators] = useState([]);
    const [mediationIdToEdit, setMediationIdToEdit] = useState(null);
    const [mediationIdToDelete, setMediationIdToDelete] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [docType, setDocType] = useState(null);
    const [mediationFiles, setMediationFiles] = useState([]);
    const [mediationFileIdToDelete, setMediationFileIdToDelete] = useState(null);

    const [formData, setFormData] = useState({
        data: '',
        oraStart: '',
        oraFinal: '',
        nrMinute: 0,
        mediatorId: '',
        observatii: ''
    })

    const defaultDate = new Date(0);
    defaultDate.setHours(0);
    defaultDate.setMinutes(0);

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    function onChangeInput(event) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    const excludeTimes = Array.from({length: 24}, (_, i) => i)
        .filter(i => i >= 22 || i <= 6)
        .map(h => Array.from({length: 60}, (_, m) => new Date().setHours(h, m)))
        .flat();


    async function handleSubmit(e) {
        e.preventDefault()
        try {
            if(mediationIdToEdit !== null) {
                await axiosInstance.put('/api/mediation/update', formData,{
                    headers: {"Content-Type": "application/json"},
                    params: {
                        mediationId: mediationIdToEdit
                    }
                }).then(() => toastr.success(`Sesiunea de mediere a candidatului ${candidate.nume} ${candidate.prenume} a fost actualizata!`, ""))
                    .catch(() => toastr.error(`Sesiunea de mediere a candidatului ${candidate.nume} ${candidate.prenume} nu a fost actualizata!`, ""))
                setMediationIdToEdit(null)
            } else {
                await axiosInstance.post('/api/mediation/add', formData,{
                    headers: { "Content-Type": "application/json" },
                    params: {
                        id1: atomId,
                        id2: formData.mediatorId
                    },
                })
            }
        } catch(error) {
            console.log(error)
        }
        setFormData({
            ...formData,
            data: new Date(),
            oraStart: new Date(),
            oraFinal: new Date(),
            nrMinute: 0,
            mediatorId: '',
            observatii: ''
        });

        getMediationSessions()
    }


    useEffect(() => {
        async function getData() {
            await axiosInstance.get('/api/candidate/id', {
                params: {
                    id: atomId
                },
            }).then(r => {
                setCandidate(r.data);
                setIsLoading(false);
            })

        }
        async function getMediators() {
            await axiosInstance.get('/api/mediator/all'
            ).then(r => {
                setMediators(r.data);
            })
        }
        getData()
        getMediators()
        getMediationSessions()
        getFiles()
    },[]);

    useEffect(() => {
        const diff = formData.oraFinal - formData.oraStart;
        setFormData({ ...formData, nrMinute: Math.round(diff / (1000 * 60)) })
    }, [formData.oraStart, formData.oraFinal]);

    function handleEdit(e, mediationId) {
        const mediation = mediationSessions.find((mediation) => mediation.id === mediationId);
        setMediationIdToEdit(mediationId)
        setFormData({
            ...formData,
            data: new Date(mediation.data),
            oraStart: new Date(mediation.oraStart),
            oraFinal: new Date(mediation.oraFinal),
            nrMinute: mediation.nrMinute,
            mediatorId: mediation.mediator.id,
            observatii: mediation.observatii
        });
    }
    if (successConfirm) {
        console.log("intra in stergere")
        if (mediationFileIdToDelete !== null) {
            try {
                axiosInstance.delete('/api/candidatemediationfile/delete/id', {
                    params: {
                        id: mediationFileIdToDelete
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getFiles();
                })
            } catch(error) {
                console.log(error)
            }
        } else if (mediationIdToDelete) {
            try {
                axiosInstance.delete('/api/mediation/delete/id', {
                    params: {
                        id: mediationIdToDelete
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getMediationSessions();
                })
            } catch(error) {
                console.log(error)
            }
        }
        setMediationFileIdToDelete(null);
        setMediationIdToDelete(null);
        setSuccessConfirm(false)
    }
    function handleDelete(e, mediationId) {
        e.preventDefault();
        setIsAlert(true);
        setMediationIdToDelete(mediationId);
    }

    async function getMediationSessions() {
        await axiosInstance.get('/api/mediation/candidate', {
            params: {
                id: atomId
            },
        }).then(r => {
            setMediationSessions(r.data);
            console.log(r.data)
        })
    }

    function tempFunc(clickedRow) {
        let newFormData = clickedRow?{ ...clickedRow, cursId: `${clickedRow.coursePrv.id}`, furnizorId: `${clickedRow.provider.id}`,data: new Date(clickedRow.data), oraStart: new Date(clickedRow.oraStart), oraFinal: new Date(clickedRow.oraFinal) }:formData;
        setFormData(newFormData)
    }

    const handleDateChange = (event) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        setFormData({ ...formData, data: formattedDate });
    };

    async function uploadFile(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", atomId);
        formData.append("type", docType);
        try {

            await axiosInstance.post('/api/candidate/add/mediationfile', formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                toastr.success(`Documentul a fost incarcat!`, "")
            }).catch(() => toastr.error(`Documentul nu a fost incarcat! Selecteaza tipul documentului.`, ""))

        } catch (error) {
            console.log(error)
        }
        getFiles();
    }

    async function getFiles() {
        await axiosInstance.get('/api/candidate/mediationfiles', {
            params: {
                id: atomId
            },
        }).then(r => {
            setMediationFiles(r.data);
            console.log(r.data)
        })
    }

    function downloadFile(e, file) {
        e.preventDefault();
        axiosInstance.get(`/api/candidatemediationfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response, file.fileName, response.type)
        })
    }



    function handleFileDelete(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setMediationFileIdToDelete(file.id);
    }

    if(isLoading) {
        return (<div></div>)
    }

    return (
        <React.Fragment>
            <CandidatiBar page="Mediere"/>
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{paddingTop: "0px"}}>
                <Container fluid>

                    <Breadcrumbs title={candidate.nume + " " + candidate.prenume} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <form onSubmit={handleSubmit}>
                                        <h4 className="card-title"><b>Sesiuni</b></h4>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-datasesiune-input" className="col-md-1 col-form-label">Data sesiune</Label>
                                            <Col md={3}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataSesiune"
                                                        className="form-control"
                                                        selected={formData.data === '' ? null : new Date(formData.data)}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                        minDate={new Date(candidate.dataIntrareGt === "" ? "" : candidate.dataIntrareGt)}
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-starttime-input" className="col-md-1 col-form-label">Ora start</Label>
                                            <Col md={3}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        onChange={(e) => setFormData({ ...formData, oraStart: e })}
                                                        className="form-control"
                                                        selected={formData.oraStart}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        // timeIntervals={15}
                                                        excludeTimes={excludeTimes}
                                                        dateFormat="HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeCaption="Time"
                                                        placeholderText="Selecteaza ora start"
                                                        autoComplete="off"
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                        <span>
                                                            <i className="far fa-clock"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-endtime-input" className="col-md-1 col-form-label">Ora final</Label>
                                            <Col md={3}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        onChange={(e) => setFormData({ ...formData, oraFinal: e })}
                                                        className="form-control"
                                                        selected={formData.oraFinal}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        minTime={formData.oraStart}
                                                        maxTime={new Date().setHours(19)}
                                                        // timeIntervals={15}
                                                        dateFormat="HH:mm"
                                                        timeFormat="HH:mm"
                                                        timeCaption="Time"
                                                        placeholderText="Selecteaza ora final"
                                                        autoComplete="off"
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-clock"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-minute-input" className="col-md-1 col-form-label">Numar minute</Label>
                                            <Col md={3}>
                                                <Input type="text" name="nrMinute" readonly="readonly" value={formData.nrMinute} id="example-minute-input" />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-1 col-form-label">Mediator</Label>
                                            <Col md={3}>
                                                <select className="form-select" name="mediatorId" value={formData.mediatorId} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza Mediator</option>
                                                    {mediators.map((mediator) => <option value={mediator.id}>{mediator.nume + " " + mediator.prenume}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-observatii-input" className="col-md-1 col-form-label">Observatii</Label>
                                            <Col md={6}>
                                                <Input type="text" name="observatii" placeholder="Scrie observatii" value={formData.observatii} id="example-observatii-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={9}></Col>
                                            <Col xs={3} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                >
                                                    Salveaza
                                                </Button>
                                            </Col>
                                        </Row>
                                        </form>
                                        <Row>
                                            <Col xs={12}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Id Sesiune</th>
                                                                        <th className="dimenisune-col">Data sesiune</th>
                                                                        <th className="dimenisune-col">Ora start</th>
                                                                        <th className="dimenisune-col">Ora final</th>
                                                                        <th className="dimenisune-col">Numar minute</th>
                                                                        <th className="dimenisune-col">Mediator</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {mediationSessions.map((mediation) => {
                                                                        return (
                                                                            <tr key={mediation.id}>
                                                                                <td>{mediation.id}</td>
                                                                                <td>{new Date(mediation.data).toLocaleDateString('ro-RO', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                                                                                <td>{new Date(mediation.oraStart).toLocaleTimeString('ro-RO', { hour: '2-digit', minute: '2-digit'})}</td>
                                                                                <td>{new Date(mediation.oraFinal).toLocaleTimeString('ro-RO', { hour: '2-digit', minute: '2-digit'})}</td>
                                                                                <td>{mediation.nrMinute}</td>
                                                                                <td>{mediation.mediator.nume + " " + mediation.mediator.prenume}</td>
                                                                                <td><Link onClick={e => handleEdit(e, mediation.id)} style={{cursor: "pointer"}}>Edit</Link> / <Link onClick={e => handleDelete(e, mediation.id)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                                            </tr>
                                                                        )
                                                                    })}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {/*<TableComponent fnc={tempFunc} receivedData={sessions} tableHeadings={["Id", "Curs", "Furnizor", "De la data", "La Data", "Locatie", "Locuri"]} tableType={"Sesiuni"}/>*/}
                                        <Row className="mt-3">
                                            <h4 className="card-title"><b>DOCUMENTE FURNIZATE</b></h4>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Tip document</Label>
                                                <Col md={5}>
                                                    <select className="form-select" name="tipDocument" onChange={(e) => setDocType(e.target.value)}>
                                                        <option value="" disabled selected hidden>Selecteaza tip document</option>
                                                        {/*<option value="Fisa individuala de analiza si proiectie profesionala">Fisa individuala de analiza si proiectie profesionala</option>*/}
                                                        {/*<option value="Chestionar BigFive">Chestionar BigFive</option>*/}
                                                        {/*<option value="Plan individual de mediere prin informare">Plan individual de mediere prin informare</option>*/}
                                                        <option value="Raport mediere 1">Raport mediere 1</option>
                                                        <option value="Oferta angajare">Oferta angajare</option>
                                                        <option value="CIM">CIM</option>
                                                        <option value="Revisal">Revisal</option>
                                                    </select>
                                                </Col>
                                                <label htmlFor="filePicker" className="col-md-1 col-form-label"  style={{cursor:"pointer"}}>
                                                    Upload
                                                    <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                                </label>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Tip doc</th>
                                                                        <th className="dimenisune-col">Fisier</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {mediationFiles.map((file) => {
                                                                        return(
                                                                            <tr key={file.id}>
                                                                                <td>{file.docType}</td>
                                                                                <td><Link onClick={e => downloadFile(e, file)} style={{cursor: "pointer"}}>Download</Link></td>
                                                                                <td><Link onClick={e => handleFileDelete(e, file)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                                            </tr>
                                                                        )
                                                                    }) }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Mediere;
