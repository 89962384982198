import React, {useState} from 'react';
import axiosInstance from "../../helpers/AxiosHelper";
import toastr from "toastr";
import {Button, Col, Input, Label, Row} from "reactstrap";

const NomenclatorObservator = () => {
    const [observatorData, setObservatorData] = useState({
        nume: '',
        prenume: '',
        cnp: ''
    })

    async function handleWatcherSubmit(e) {
        e.preventDefault()
        try {
            await axiosInstance.post('/api/watcher/add', observatorData,{
                headers: {"Content-Type": "application/json"}
            }).then(() => toastr.success(`Observatorul a fost adaugat!`, ""))
                .catch(() => toastr.error(`Observatorul nu a fost adaugat!`, ""))

        } catch(error) {
            console.log(error)
        }
        setObservatorData({
            ...observatorData,
            nume: '',
            prenume: '',
            cnp: '',
        });
    }

    function onChangeInput(e) {
        setObservatorData({...observatorData, [e.target.name]: e.target.value})
    }

    return (
        <form onSubmit={handleWatcherSubmit}>
            <h4 className="card-title"><b>Observator</b></h4>
            <Row className="mb-3">
                <Label htmlFor="example-nume-input" className="col-md-2 col-form-label">Nume</Label>
                <Col md={10}>
                    <Input type="text" name="nume" value={observatorData.nume} placeholder="Introdu Nume" id="example-nume-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label htmlFor="example-cui-input" className="col-md-2 col-form-label">Prenume</Label>
                <Col md={10}>
                    <Input type="text" name="prenume" value={observatorData.prenume} placeholder="Introdu prenume" id="example-cui-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label htmlFor="example-cui-input" className="col-md-2 col-form-label">CNP</Label>
                <Col md={10}>
                    <Input type="text" name="cnp" value={observatorData.cnp} placeholder="Introdu CNP" id="example-cui-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row>
                <Col xs={9}></Col>
                <Col xs={3} align="center">
                    <Button
                        color="dark"
                        className="waves-effect waves-light me-1"
                    >
                        Adauga Observator
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default NomenclatorObservator;
