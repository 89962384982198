import React from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";

const CandidatiBar = ({page}) => {
    const [atomId, setAtomId] = useAtom(idAtom);
    return (
        <React.Fragment>
            <div className="page-content" style={{paddingBottom: "0px"}}>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <Table id="tech-companies-1" striped bordered responsive>
                                        <thead>
                                        <tr style={{backgroundColor: "lightblue"}}>

                                            {page === "Formular Candidati" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to="/formular-candidati">Formular</Link></th>
                                            )}
                                            {page !== "Formular Candidati" && (
                                                <th className="dimenisune-col"><Link to="/formular-candidati">Formular</Link></th>

                                            )}
                                            {atomId !== null && (

                                                    <>
                                            {page === "Documente" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to="/documente">Documente</Link></th>
                                                )}
                                            {page !== "Documente" && (
                                                <th className="dimenisune-col"><Link to="/documente">Documente</Link></th>

                                                )}
                                            {page === "Date Raportare" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to={"/date-raportare"}>Date raportare</Link></th>
                                                )}
                                            {page !== "Date Raportare" && (
                                                <th className="dimenisune-col"><Link to={"/date-raportare"}>Date raportare</Link></th>

                                                )}
                                            {page === "Mediere" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to={"/mediere"}>Mediere</Link></th>
                                                )}
                                            {page !== "Mediere" && (
                                                <th className="dimenisune-col"><Link to={"/mediere"}>Mediere</Link></th>

                                                )}
                                            {page === "Cursuri Candidati" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to={"/cursuri-candidati"}>Cursuri</Link></th>
                                                )}
                                            {page !== "Cursuri Candidati" && (
                                                <th className="dimenisune-col"><Link to={"/cursuri-candidati"}>Cursuri</Link></th>

                                                )}
                                            {page === "Profil detaliat" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to={"/profil"}>Profil detaliat</Link></th>
                                                )}
                                            {page !== "Profil detaliat" && (
                                                <th className="dimenisune-col"><Link to={"/profil"}>Profil detaliat</Link></th>

                                                )}
                                                </>


                                            )}

                                        </tr>
                                        </thead>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CandidatiBar;
