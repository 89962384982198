import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Spinner, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/stickyColumn.scss"
import {Link} from "react-router-dom";
import axiosInstance from "../../helpers/AxiosHelper";
// import {atom, useAtom} from "jotai";
import TableComponent, {idAtom} from "../Cursuri/TableComponent";
import {useAtom} from "jotai";
import FileDownload from "js-file-download";

// export const idAtom = atom(null);

const CandidatiTableFunction = () => {

    const [atomId, setAtomId] = useAtom(idAtom);


    const breadcrumbItems = [
        {title: "Tables", link: "#"},
        {title: "Responsive Table", link: "#"},
    ];

    const [candidates, setCandidates] = useState([]);

    function findCourseDenumire(candidateId, isCursPrincipal) {
        const candidate = candidates.find(candidate => candidate.id === candidateId);
        const course = candidate.courses.find(element => element.principal === isCursPrincipal);
        if (course !== undefined) {
            return course.denumire
        }
        return "-";
    }

    function findProvider(candidateId, isCursPrincipal) {
        const candidate = candidates.find(candidate => candidate.id === candidateId);
        const course = candidate.courses.find(element => element.principal === isCursPrincipal);
        if (course !== undefined) {
            const session = candidate.sessions.find(session => session.coursePrv.id === course.id);
            if (session !== undefined) {
                return session.provider.nume
            }
        }

        return "-";
    }

    function findDataStartCurs(candidateId,isCursPrincipal) {
        const candidate = candidates.find(candidate => candidate.id === candidateId);
        const course = candidate.courses.find(element => element.principal === isCursPrincipal);
        if (course !== undefined) {
            const session = candidate.sessions.find(session => session.coursePrv.id === course.id);
            if (session !== undefined) {
                return getDateFormat(session.dataStart)
            }
        }

        return "-";
    }

    function findDataFinalCurs(candidateId, isCursPrincipal) {
        const candidate = candidates.find(candidate => candidate.id === candidateId);
        const course = candidate.courses.find(element => element.principal === isCursPrincipal);
        if (course !== undefined) {
            const session = candidate.sessions.find(session => session.coursePrv.id === course.id);
            if (session !== undefined) {
                return getDateFormat(session.dataFinal)
            }
        }

        return "-";
    }

    useEffect(() => {
        if(atomId !== null) setAtomId(null);
        axiosInstance.get('/api/candidate/all').then((response) => {
            setCandidates(response.data);
            console.log(response.data)
        })
    }, []);

    function getDateFormat(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    function getAge(dataIntrareGt, cnp) {
        const dataReferinta = new Date(dataIntrareGt);
        const birthDate = new Date((cnp.charAt(0) === '1' || cnp.charAt(0) === '2' ? "19" : "20") + cnp.charAt(1) + cnp.charAt(2) + "-" + cnp.charAt(3) + cnp.charAt(4) +
            "-" + cnp.charAt(5) + cnp.charAt(6) + "T00:00:00.000Z");
        return dataReferinta.getFullYear() - birthDate.getFullYear() + " ani";
    }

    function tempFunc(clickedRow) { // Recreez form data din cum arata randul in tabel
        console.log(clickedRow)
        // let newFormData = clickedRow?{ ...clickedRow, cursId: `${clickedRow.coursePrv.id}`, furnizorId: `${clickedRow.provider.id}`,dataStart: new Date(clickedRow.dataStart), dataFinal: new Date(clickedRow.dataFinal) }:emptyForm;
        // setFormData(newFormData)
    }

    function downloadForm(e) {
        e.preventDefault();
        axiosInstance.post(`/api/xlsm/generate`, [687], {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response, "form de test.xlsm", response.type)
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Tabel Candidati" breadcrumbItems={breadcrumbItems}/>

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    {candidates.length === 0 ? (
                                        <div align="center">
                                            <Spinner className="me-2" color="primary" />
                                            <p className="text-muted">Se conecteaza la baza de date...</p>
                                        </div>
                                    ) : <>
                                    <Row>
                                        <Col md={2}>

                                            <Link to="/formular-candidati"><h4 className="card-title"><i className="ri-user-add-fill" style={{  display: "inline-block",
                                                verticalAlign: "middle"}}></i> Adauga</h4></Link>
                                        </Col>
                                        <Col md={8}></Col>
                                        <Col md={2}>
                                            {/*<h4 className="card-title" onClick={downloadForm} style={{cursor: "pointer", textAlign: "right"}}>Export <i className="fas fa-file-export"></i></h4>*/}
                                        </Col>
                                    </Row>
                                    <TableComponent fnc={tempFunc} receivedData={candidates} tableHeadings={["CNP", "Nume", "Prenume", "Judet CI", "Mediu CI", "Judet resedinta", "Mediu resedinta", "Etnie Roma", "Categorie GT", "Data intrare in GT", "Varsta la data intrarii in GT", "Profil", "Data start mediere", "Data end mediere", "Nr sesiuni mediere", "Total ore mediere", "Stadiu mediere", "Data start perioada curs", "Data end perioada curs", "Nr cursuri alocate", "Curs principal", "Furnizor curs principal", "Stadiu curs", "Data start perioada curs secundar", "Data end perioada curs secundar", "Curs secundar", "Furnizor curs secundar", "Stadiu curs secundar", "Data iesire GT", "Data plata subventie", "Gen", "Email", "Telefon", "Partener", "Tip somer", "Status"]} tableType={"Candidati"}/>
                                    </>}
                                    </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CandidatiTableFunction;
