import React from 'react';
import {Col, Container, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";

const CursuriBar = ({page}) => {
    return (
        <React.Fragment>
            <div className="page-content" style={{paddingBottom: "0px"}}>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <Table id="tech-companies-1" striped bordered responsive>
                                        <thead>
                                        <tr style={{backgroundColor: "lightblue"}}>
                                            {page === "Cursuri" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to="/cursuri">Cursuri</Link></th>
                                            )}
                                            {page !== "Cursuri" && (
                                                <th className="dimenisune-col"><Link to="/cursuri">Cursuri</Link></th>

                                            )}
                                            {page === "Sesiuni" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to="/sesiuni">Sesiuni</Link></th>
                                            )}
                                            {page !== "Sesiuni" && (
                                                <th className="dimenisune-col"><Link to="/sesiuni">Sesiuni</Link></th>

                                            )}
                                            {page === "Alocare Cursuri" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to={"/alocare-cursuri"}>Alocare cursuri</Link></th>
                                            )}
                                            {page !== "Alocare Cursuri" && (
                                                <th className="dimenisune-col"><Link to={"/alocare-cursuri"}>Alocare cursuri</Link></th>

                                            )}
                                            {page === "Rezultate Cursuri" && (
                                                <th className="dimenisune-col" style={{backgroundColor: "lightgreen"}}><Link to={"/rezultate-cursuri"}>Rezultate cursuri</Link></th>
                                            )}
                                            {page !== "Rezultate Cursuri" && (
                                                <th className="dimenisune-col"><Link to={"/rezultate-cursuri"}>Rezultate cursuri</Link></th>

                                            )}
                                        </tr>
                                        </thead>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CursuriBar;
