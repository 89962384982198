import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link, useLocation} from "react-router-dom";
import CandidatiBar from "./CandidatiBar";
import axiosInstance from "../../helpers/AxiosHelper";
import FileDownload from "js-file-download";
import {atom, useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import DatePicker from "react-datepicker";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";

// export const idAtom = atom(null);


const Documente = () => {
    const [fileToSend, setFileToSend] = useState(null);
    const [candidateFiles, setCandidateFiles] = useState([]);
    // const location = useLocation();
    // const idParam = location.pathname.substring(11);
    const [atomId, setAtomId] = useAtom(idAtom);
    const [docType, setDocType] = useState(null);
    const [docDate, setDocDate] = useState('');
    const [docTypeToGenerate, setDocTypeToGenerate] = useState(null);
    const [candidateGeneratedFiles, setCandidateGeneratedFiles] = useState([]);
    const [candidate, setCandidate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [finalDoc, setFinalDoc] = useState("Draft");
    const [generatedFileIdToDelete, setGeneratedFileIdToDelete] = useState(null);
    const [scanFileIdToDelete, setScanFileIdToDelete] = useState(null);
    const [candidateFileIdToDelete, setCandidateFileIdToDelete] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    async function getFiles() {
        try {
            const { data } = await axiosInstance.get('/api/candidate/files', {
                params: { id: atomId },
            });
            setCandidateFiles(data);
        } catch (error) {
            console.log(error)
            toastr.error('Fisierele nu au fost extrase din baza de date. Contacteaza echipa suport!', "")
        }
    }


    async function getGeneratedFiles() {
        try {
            const { data } = await axiosInstance.get('/api/candidate/generatedfiles', {
                params: {id: atomId},
            });
            setCandidateGeneratedFiles(data)
        } catch (error) {
            console.log(error)
            toastr.error('Fisierele Generate nu au fost extrase din baza de date. Contacteaza echipa suport!', "")
        }

    }

    async function getCandidate() {
        try {
            const { data } = await axiosInstance.get('/api/candidate/id', {
                params: {id: atomId},
            });
            setCandidate(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            toastr.error('Datele candidatului nu au putut fi accesate. Contacteaza echipa suport!', "")
        }
    }

    useEffect(() => {
        getCandidate()
        getFiles()
        getGeneratedFiles()
    },[]);


    async function handleSubmit(e) {
        e.preventDefault();
        setIsGenerating(true);
        const formData = new FormData();
        formData.append("date", docDate);
        formData.append("type", docTypeToGenerate);
        formData.append("finalDoc", finalDoc);
        formData.append("id", atomId);

        try {
            const {status} = await axiosInstance.post(
                `/api/candidate/generate${docTypeToGenerate === 'Toate documentele' ? '/zip' : ''}`, formData,
            );
            if (status !== 200) throw new Error(`Error! status: ${status}`);
            setIsGenerating(false);
            getGeneratedFiles();
        } catch (error) {
            console.error(error);
            setIsGenerating(false);
            toastr.error(`${error.response?.data ?? 'Failed to generate document.'}`, '');
        }
    }

    function onChangeInput(e) {
        const { type, value, checked } = e.target;

        if (type === "checkbox") setFinalDoc(checked ? "Final" : "Draft");
        else setDocTypeToGenerate(value)
    }

    async function uploadFile(e) {
        e.preventDefault();
        setFileToSend(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", atomId);
        formData.append("type", docType);
        try {
            const { status } = await axiosInstance.post('/api/candidate/add/file', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (status === 200 || status === 201) {
                toastr.success('Documentul a fost incarcat!', '');
            } else {
                throw new Error(`Error! status: ${status}`);
            }
        } catch (error) {
            console.error(error);
            toastr.error('Documentul nu a fost incarcat! Selecteaza tipul documentului.', '');
        }
        getFiles();
    }


    async function downloadFile(e, file, typeOfFile) {
        e.preventDefault();
        try {
            const { data } = await axiosInstance.get(`/api/${typeOfFile}/download/${file.id}`, {
                responseType: 'blob',
            });
            FileDownload(data, typeOfFile === 'scanfile' ? "scan_" + file.fileName : file.fileName, data.type);
        } catch (error) {
            console.error(error);
            toastr.error('Descarcarea a esuat! Contacteaza echipa suport!', '');
        }
    }

    function onChangeDocType(e) {
        setDocType(e.target.value)
    }

    if (successConfirm) {
        deleteFiles()
        setCandidateFileIdToDelete(null);
        setScanFileIdToDelete(null);
        setGeneratedFileIdToDelete(null);
        setSuccessConfirm(false)
    }

    async function deleteFiles() {
        let endpoint;
        let idToDelete;

        if (generatedFileIdToDelete !== null) {
            endpoint = '/api/candidate/generatedfile/delete/id';
            idToDelete = generatedFileIdToDelete;
        } else if (scanFileIdToDelete !== null) {
            endpoint = '/api/scanfile/delete/id';
            idToDelete = scanFileIdToDelete;
        } else if (candidateFileIdToDelete !== null) {
            endpoint = '/api/candidatefile/delete/id';
            idToDelete = candidateFileIdToDelete;
        }

        if (endpoint && idToDelete) {
            try {
                const response = await axiosInstance.delete(endpoint, {
                    params: { id: idToDelete },
                });
                if (response?.status === 200) {
                    if (generatedFileIdToDelete !== null) getGeneratedFiles();
                    else if (scanFileIdToDelete !== null) getGeneratedFiles();
                    else if (candidateFileIdToDelete !== null) getFiles();
                } else {
                    throw new Error(`Error! status: ${response?.status}`);
                }
            } catch (error) {
                console.log(error);
                toastr.error('Documentul nu a fost sters! Contacteaza echipa suport!', '');
            }
        }
    }

    function handleDelete(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setGeneratedFileIdToDelete(file.id)
    }

    function handleFileDelete(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setCandidateFileIdToDelete(file.id);
    }

    async function uploadScanFile(e, file) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", file.id);
        try {
            const response = await axiosInstance.post("/api/scanfile/add/file", formData,
                {headers: { "Content-Type": "multipart/form-data" },
            });
            if (response.status === 200 || response.status === 201) toastr.success("Documentul a fost incarcat!", "");
            else throw new Error(`Error! status: ${response.status}`);

        } catch (error) {
            toastr.error("Documentul nu a fost incarcat! A aparut o eroare.", "");
            console.log(error);
        }
        getGeneratedFiles();
    }

    function deleteScanFile(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setScanFileIdToDelete(file.id);
    }

    function getDateFormat(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    const handleDateChange = (event) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        setDocDate(formattedDate);
    };

    if(isLoading) {
        return (<div></div>)
    }

    return (
        <React.Fragment>
            <CandidatiBar page="Documente" />
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }}
                    onCancel={() => {
                        setIsAlert(false);
                    }}
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{paddingTop: "0px"}}>
                <Container fluid>

                    <Breadcrumbs title={candidate.nume + " " + candidate.prenume} />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><b>DOCUMENTE GENERATE</b></h4>
                                        <form onSubmit={handleSubmit}>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Tip document</Label>
                                                <Col md={5}>
                                                    <select className="form-select" name="tipDocument" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza tip document</option>
                                                        <option value="Toate documentele">Toate documentele</option>
                                                        <option value="Anexa 0 Fisa de informare">Anexa 0 Fisa de informare</option>
                                                        <option value="Anexa 1 Acord date personale">Anexa 1 Acord date personale</option>
                                                        <option value="Anexa 2 Cerere de inscriere in proiect">Anexa 2 Cerere de inscriere in proiect</option>
                                                        <option value="Anexa 3  Evitarea dublei finantari">Anexa 3  Evitarea dublei finantari</option>
                                                        <option value="Anexa 4 Eligibilitate Grup Tinta">Anexa 4 Eligibilitate Grup Tinta</option>
                                                        <option value="Anexa 5 Confirmare selectie in grupul tinta">Anexa 5 Confirmare selectie in grupul tinta</option>
                                                        <option value="Anexa 6 Masuri aplicate">Anexa 6 Masuri aplicate</option>
                                                        <option value="Anexa 7 Declaratie privind starea de sanatate">Anexa 7 Declaratie privind starea de sanatate</option>
                                                        <option value="Anexa 8 Somer de lunga durata">Anexa 8 Somer de lunga durata</option>
                                                        <option value="Anexa 9 Declaratie Roma">Anexa 9 Declaratie Roma</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-datadocument-input" className="col-md-1 col-form-label">Data Document</Label>
                                                <Col md={3}>
                                                    <div style={{position: "relative"}}>
                                                        <DatePicker
                                                            name="dataDocument"
                                                            className="form-control"
                                                            selected={docDate === '' ? null : new Date(docDate)}
                                                            onChange={(event) => handleDateChange(event)}
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="Selecteaza data"
                                                            autoComplete="off"
                                                            minDate={new Date(candidate.dataIntrareGt)}
                                                        />
                                                        <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Final</Label>
                                                <Col md={10}>
                                                    <div className="form-check mb-3">
                                                        <Input className="form-check-input" name="final" type="checkbox" value="" id="defaultCheck2" onChange={onChangeInput}  />
                                                        <Label className="form-check-label" htmlFor="defaultCheck2">
                                                        </Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={9}></Col>
                                                <Col xs={3} align="center">
                                                    <Button
                                                        color="dark"
                                                        className="waves-effect waves-light me-1"
                                                    >
                                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                            {isGenerating ? <Spinner className="me-2" color="primary" style={{width: "20px", height: "20px"}} /> : <>Genereaza</>}
                                                        </div>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        <h4 className="card-title"><b>Show all</b></h4>
                                        <Row>
                                            <Col xs={12}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Tip doc</th>
                                                                        <th className="dimenisune-col">Data document</th>
                                                                        <th className="dimenisune-col">Status</th>
                                                                        <th className="dimenisune-col">Link regenerare</th>
                                                                        <th className="dimenisune-col">Scan</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {candidateGeneratedFiles.map((file) => {
                                                                        return(
                                                                            <tr key={file.id}>
                                                                                <td>{file.docType}</td>
                                                                                <td>{file.docDate === "" ? "" : getDateFormat(file.docDate)}</td>
                                                                                <td>{file.finalDoc}</td>
                                                                                <td><Link onClick={e => downloadFile(e, file, 'candidate/generatedfile')} style={{cursor: "pointer"}}>Regenereaza</Link></td>
                                                                                {!file.isScan && (
                                                                                    <td>
                                                                                        <label htmlFor="filePicker1"  style={{cursor:"pointer"}}>
                                                                                            Upload
                                                                                            <input id="filePicker1" type="file" name="file1" onChange={e => uploadScanFile(e, file)} style={{display: "none"}}></input>
                                                                                        </label>
                                                                                    </td>
                                                                                )}
                                                                                {file.isScan && (
                                                                                    <td>
                                                                                        <Link onClick={e => downloadFile(e, file, 'scanfile')} style={{cursor: "pointer"}}>Download</Link>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        <i className="fas fa-trash-alt" onClick={e => deleteScanFile(e, file)} style={{cursor: "pointer"}}></i>
                                                                                    </td>

                                                                                )}
                                                                                <td><Link onClick={e => handleDelete(e, file)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                                            </tr>
                                                                        )
                                                                    }) }

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <h4 className="card-title"><b>DOCUMENTE FURNIZATE</b></h4>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Tip document</Label>
                                                <Col md={5}>
                                                    <select className="form-select" name="tipDocument" onChange={onChangeDocType}>
                                                        <option value="" disabled selected hidden>Selecteaza tip document</option>
                                                        <option value="Copie CI">Copie CI</option>
                                                        <option value="Diploma ultima scoala absolvita">Diploma ultima scoala absolvita</option>
                                                        <option value="Adeverinta ultima scoala absolvita">Adeverinta ultima scoala absolvita</option>
                                                        <option value="Copie a certificatului de căsătorie">Copie a certificatului de căsătorie</option>
                                                        <option value="Adeverință de vechime">Adeverință de vechime</option>
                                                        <option value="Decizie incetare CIM">Decizie incetare CIM</option>
                                                        <option value="Adeverinta AJOFM">Adeverinta AJOFM</option>
                                                        <option value="Copie a certificatului de nastere">Copie a certificatului de nastere</option>
                                                    </select>
                                                </Col>
                                                <label htmlFor="filePicker" className="col-md-1 col-form-label"  style={{cursor:"pointer"}}>
                                                    Upload
                                                    <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                                </label>
                                            </FormGroup>
                                        </Row>

                                        <Row>
                                            <Col xs={6}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Tip doc</th>
                                                                        <th className="dimenisune-col">Fisier</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {candidateFiles.map((file) => {
                                                                        return(
                                                                            <tr key={file.id}>
                                                                                <td>{file.docType}</td>
                                                                                <td><Link onClick={e => downloadFile(e, file,'candidatefile')} style={{cursor: "pointer"}}>Download</Link></td>
                                                                                <td><Link onClick={e => handleFileDelete(e, file)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                                            </tr>
                                                                        )
                                                                    }) }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    <br/>
                    <br/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Documente;
