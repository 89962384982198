import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CandidatiBar from "./CandidatiBar";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import axiosInstance from "../../helpers/AxiosHelper";
import DatePicker from "react-datepicker";
import toastr from "toastr";

const DateRaportare = () => {

    const [atomId, setAtomId] = useAtom(idAtom);
    const [candidate, setCandidate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        profil: '',
        dataIntrareGt: '',
        dataStartMediere: '',
        dataEndMediere: '',
        dataStartCursuri: '',
        dataEndCursuri: '',
        dataIesireGt: '',
        motivIesireGt: '',
        dataPlataSubventie: '',
    })

    useEffect(() => {
        if (atomId !== null) {
            async function getCandidate() {
                try {
                    const { data } = await axiosInstance.get('/api/candidate/id', {
                        params: { id: atomId }
                    });
                    setCandidate(data);
                    const filteredEntries = Object.entries(data).filter(([key]) => key in formData);
                    const filteredObject = Object.fromEntries(filteredEntries);
                    setFormData({...formData, ...filteredObject});
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                    toastr.error(`Eroare de citire date candidat. Contacteaza echipa suport.`, "");
                }
            }
            getCandidate();
        }
    },[]);

    function onChangeInput(e) {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const response = await axiosInstance.patch('/api/candidate/update', formData,{
                headers: {"Content-Type": "application/json"},
                params: {id: atomId},
            });
            if (response.status === 200) {
                toastr.success(`Candidatul ${candidate.nume} ${candidate.prenume} a fost actualizat!`, "");
            } else {
                throw new Error(`Error! status: ${response.status}`);
            }
        } catch (error) {
            console.log(error);
            toastr.error(`Candidatul ${candidate.nume} ${candidate.prenume} nu a fost actualizat!`, "");
        }
    }

    function uploadFile() {

    }



    const getDataStartCurs = () => {
        const sessions = candidate.sessions;
        if (sessions.length > 0) {
            const session = sessions.reduce((earliest, session) => {
                return new Date(session.dataStart) < new Date(earliest.dataStart) ? session : earliest;
            });
            return new Date(session.dataStart);
        }
        return undefined;
    }
    const getDataEndCurs = () => {
        const sessions = candidate.sessions;
        if (sessions.length > 0) {
            const session = sessions.reduce((latest, session) => {
                return new Date(session.dataFinal) > new Date(latest.dataFinal) ? session : latest;
            });
            return new Date(session.dataFinal);
        }
        return undefined;
    }

    const handleDateChange = (event, name) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        setFormData({ ...formData, [name]: formattedDate });
    };

    if(isLoading) {
        return (<div></div>)
    }

    return (
        <React.Fragment>
            <CandidatiBar page="Date Raportare"/>

            <div className="page-content" style={{paddingTop: "0px"}}>
                <Container fluid>

                    <Breadcrumbs title={candidate.nume + " " + candidate.prenume} />
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-2 col-form-label">Profil</Label>
                                            <Col md={4}>
                                                <select className="form-select" value={formData.profil} name="profil" onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza profil</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                </select>
                                            </Col>
                                        </FormGroup>

                                        <Row className="mb-3">
                                            <Label htmlFor="example-dataintrareingt-input" className="col-md-2 col-form-label">Data intrare in GT</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataIntrareGt"
                                                        className="form-control"
                                                        selected={formData.dataIntrareGt === '' || formData.dataIntrareGt === null ? null : new Date(formData.dataIntrareGt)}
                                                        onChange={(event) => handleDateChange(event, "dataIntrareGt")}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-datastartmediere-input" className="col-md-2 col-form-label">Data start mediere</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataStartMediere"
                                                        className="form-control"
                                                        selected={formData.dataStartMediere === '' || formData.dataStartMediere === null ? null : new Date(formData.dataStartMediere.toLocaleString())}
                                                        onChange={(event) => handleDateChange(event, "dataStartMediere")}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                        minDate={new Date(candidate.dataIntrareGt === "" ? formData.dataIntrareGt : candidate.dataIntrareGt)}
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Label htmlFor="example-dataendmediere-input" className="col-md-2 col-form-label">Data end mediere</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataEndMediere"
                                                        className="form-control"
                                                        selected={formData.dataEndMediere === '' || formData.dataEndMediere === null ? null : new Date(formData.dataEndMediere)}
                                                        onChange={(event) => handleDateChange(event, "dataEndMediere")}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                        minDate={new Date(candidate.dataIntrareGt === "" ? formData.dataIntrareGt : candidate.dataIntrareGt)}
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-datastartcursuri-input" className="col-md-2 col-form-label">Data start cursuri</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataStartCursuri"
                                                        className="form-control"
                                                        selected={getDataStartCurs()}
                                                        dateFormat="dd-MM-yyyy"
                                                        disabled
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Label htmlFor="example-dataendcursuri-input" className="col-md-2 col-form-label">Data end cursuri</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataEndCursuri"
                                                        className="form-control"
                                                        selected={getDataEndCurs()}
                                                        dateFormat="dd-MM-yyyy"
                                                        disabled
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-dataiesiregt-input" className="col-md-2 col-form-label">Data iesire din GT</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataIesireGt"
                                                        className="form-control"
                                                        selected={formData.dataIesireGt === '' || formData.dataIesireGt === null ? null : new Date(formData.dataIesireGt)}
                                                        onChange={(event) => handleDateChange(event, "dataIesireGt")}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                        minDate={new Date(candidate.dataIntrareGt === "" ? formData.dataIntrareGt : candidate.dataIntrareGt)}
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label htmlFor="example-motiv-input" className="col-md-2 col-form-label">Motiv iesire din GT</Label>
                                            <Col md={6}>
                                                <Input type="text" name="motivIesireGt" value={formData.motivIesireGt} placeholder="Scrie motivul" id="example-motiv-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-dataplatasubventie-input" className="col-md-2 col-form-label">Data plata subventie</Label>
                                            <Col md={4}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataPlataSubventie"
                                                        className="form-control"
                                                        selected={formData.dataPlataSubventie === '' || formData.dataPlataSubventie === null ? null : new Date(formData.dataPlataSubventie)}
                                                        onChange={(event) => handleDateChange(event, "dataPlataSubventie")}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                <span>
                                                    <i className="far fa-calendar"></i>
                                                </span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <label className="col-md-2 col-form-label" htmlFor="filePicker"  style={{cursor:"pointer"}}>
                                                    Upload
                                                    <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                            </label>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <h4 className="card-title" style={{backgroundColor: "black", color: "white"}}><b>Sectiunea B - La iesirea din operatiune</b></h4>
                                            </Col>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-4 col-form-label">Situația pe piața forței de muncă</Label>
                                                <Col md={8}>
                                                    <select className="form-select" name="situatiaFm" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza situatie</option>
                                                        <option value="1">Angajat</option>
                                                        <option value="2">Persoana care are un loc de munca la incetarea calitatii de participant</option>
                                                        <option value="3">Persoana care desfasoare o activitate independenta la incetarea calitatii de participant</option>
                                                        <option value="4">Somer</option>
                                                        <option value="5">Inactiv</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-4 col-form-label">Detalierea statului pe piața muncii - categoria angajați</Label>
                                                <Col md={8}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-4 col-form-label">Detalierea statului pe piața muncii - categoria persoane inactive</Label>
                                                <Col md={8}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-4 col-form-label">Înscris într-un program de educație/ formare</Label>
                                                <Col md={8}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-4 col-form-label">Angajată în dobândirea unei claificări</Label>
                                                <Col md={8}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-4 col-form-label">A primit ofertă de muncă/ formare/ ucenicie/ stagiu</Label>
                                                <Col md={8}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-4 col-form-label">Nivel educatie</Label>
                                                <Col md={8}>
                                                    <select className="form-select" name="nivelEducatie" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza nivel educatie</option>
                                                        <option value="0">Studii educatie timpurie (ISCED 0)</option>
                                                        <option value="1">Studii primare (ISCED 1)</option>
                                                        <option value="2">Studii gimnaziale (ISCED 2)</option>
                                                        <option value="3">Studii liceale (ISCED 3)</option>
                                                        <option value="4">Studii postliceale (ISCED 4)</option>
                                                        <option value="5">Studii superioare (ISCED 5)</option>
                                                        <option value="6">Studii superioare (ISCED 6)</option>
                                                        <option value="7">Studii superioare (ISCED 7)</option>
                                                        <option value="8">Studii superioare (ISCED 8)</option>
                                                        <option value="9">fara ISCED</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <h4 className="card-title" style={{backgroundColor: "black", color: "white"}}><b>Sectiunea C - La iesirea din operatiune?</b></h4>
                                            </Col>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Persoană care are un loc de muncă</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Persoană care desfășoară o activitate independentă</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Persoană a cărei situație pe piața forței de muncă s-a îmbunătățit</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Participant cu vârstă de peste 54 ani care are un loc de muncă</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Participant cu vârstă de peste 54 ani care are desfășoară o activitate independentă</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Persoană dezavantajată care are un loc de muncă</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Persoană dezavantajată care desfășoară o activitate independentă</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-email-input" className="col-md-7 col-form-label">Persoană inclusă în programe de educație continuă, de formare în vederea obținerii unei diplome, de ucenicie sau de stagiu</Label>
                                                <Col md={5}>
                                                    <Input type="text" name="email" placeholder="" id="example-email-input" onChange={onChangeInput} />
                                                </Col>
                                            </Row>
                                        </Row>
                                        <Row>
                                            <Col xs={11}></Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                >
                                                    Salveaza
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    </form>
                    <br/>
                    <br/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DateRaportare;
