import React, {useEffect, useState} from 'react';
import CandidatiBar from "./CandidatiBar";
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axiosInstance from "../../helpers/AxiosHelper";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import DatePicker from "react-datepicker";
import toastr from "toastr";
import {Link} from "react-router-dom";
import FileDownload from "js-file-download";
import SweetAlert from "react-bootstrap-sweetalert";

const ProfilDetaliat = () => {

    const [atomId, setAtomId] = useAtom(idAtom);
    const [candidate, setCandidate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [watchers, setWatchers] = useState([]);
    const [observations, setObservations] = useState([]);
    const [observationIdToEdit, setObservationIdToEdit] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [isCvToDelete, setIsCvToDelete] = useState(false);
    const [isPlanToDelete, setIsPlanToDelete] = useState(false);
    const [formData, setFormData] = useState({
        data: '',
        watcherId: '',
        observatii: ''
    })

    async function getCandidate() {
        await axiosInstance.get('/api/candidate/id', {
            params: {
                id: atomId
            },
        }).then(r => {
            setCandidate(r.data);
            console.log(r.data)
            setIsLoading(false);
        })
    }

    useEffect(() => {
        async function getWatchers() {
            await axiosInstance.get('/api/watcher/all'
            ).then(r => {
                setWatchers(r.data);

            })
        }
        getWatchers()
        getCandidate()
        getObservations()
    },[]);

    function onChangeInput(event) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    const handleDateChange = (event) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        console.log(formattedDate)
        setFormData({ ...formData, data: formattedDate });
    };

    if(isLoading) {
        return (<div></div>)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            if(observationIdToEdit !== null) {
                await axiosInstance.put('/api/observation/update', formData,{
                    headers: {"Content-Type": "application/json"},
                    params: {
                        observationId: observationIdToEdit
                    }
                }).then(() => toastr.success(`Observatia candidatului ${candidate.nume} ${candidate.prenume} a fost actualizata!`, ""))
                    .catch(() => toastr.error(`Observatia candidatului ${candidate.nume} ${candidate.prenume} nu a fost actualizata!`, ""))
                setObservationIdToEdit(null);
            } else {
                await axiosInstance.post('/api/observation/add', formData,{
                    headers: { "Content-Type": "application/json" },
                    params: {
                        id1: atomId,
                        id2: formData.watcherId
                    },
                }).then(() => toastr.success(`Observatia candidatului ${candidate.nume} ${candidate.prenume} a fost adaugata!`, ""))
                    .catch(() => toastr.error(`Observatia candidatului ${candidate.nume} ${candidate.prenume} nu a fost adaugata! Completeaza campurile.`, ""))
            }
        } catch(error) {
            console.log(error)
        }
        setFormData({
            ...formData,
            data: '',
            mediatorId: '',
            observatii: ''
        });
        getObservations();
    }

    async function getObservations() {
        await axiosInstance.get('/api/observation/candidate', {
            params: {
                id: atomId
            },
        }).then(r => {
            setObservations(r.data);
            console.log(r.data)
        })
    }

    async function uploadFile(e, typeToUpload) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", atomId);
        try {
            await axiosInstance.post(`/api/${typeToUpload}/add`, formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201 )) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                toastr.success(`Documentul a fost incarcat!`, "")
            }).catch(() => toastr.error(`Documentul nu a fost incarcat!`, ""))
        } catch (error) {
            console.log(error)
        }
        getCandidate();
    }

    function downloadFile(e, typeToDownload) {
        e.preventDefault();
        axiosInstance.get(`/api/${typeToDownload}/download/${atomId}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response, typeToDownload + "_" + candidate.nume + "_" + candidate.prenume, response.type)
        })
    }

    if (successConfirm) {
        // if (isCvToDelete) {
        try {
            axiosInstance.delete(`/api/${isCvToDelete ? "cv" : "plan"}/delete/id`, {
                params: {
                    id: atomId
                },
            }).then(res => {
                if (!(res.status === 200)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                const response = res.data;
                console.log(response)
                getCandidate();
            })
        } catch(error) {
            console.log(error)
        }
        // } else if (isPlanToDelete) {
        //     try {
        //         axiosInstance.delete('/api/plan/delete/id', {
        //             params: {
        //                 id: atomId
        //             },
        //         }).then(res => {
        //             if (!(res.status === 200)) {
        //                 throw new Error(`Error! status: ${res.status}`);
        //             }
        //             const response = res.data;
        //             console.log(response)
        //             getCandidate();
        //         })
        //     } catch(error) {
        //         console.log(error)
        //     }
        // }

    }

    function deleteFile(e, typeToDelete) {
        e.preventDefault();
        setIsAlert(true);
        typeToDelete === "cv" ? setIsCvToDelete(true) : setIsPlanToDelete(true);
    }

    return (
        <React.Fragment>
            <CandidatiBar page="Profil detaliat"/>
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{paddingTop: "0px"}}>
                <Container fluid>

                    <Breadcrumbs title={candidate.nume + " " + candidate.prenume} />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cv-input" className="col-md-1 col-form-label">CV</Label>

                                            {!candidate.isCv && (
                                                    <>
                                                        <Col>
                                                            <label className="col-md-2 col-form-label" htmlFor="filePicker1"  style={{cursor:"pointer"}}>
                                                                Upload
                                                                <input id="filePicker1" type="file" name="file" onChange={(e) => uploadFile(e, "cv")} style={{display: "none"}}></input>
                                                            </label>
                                                        </Col>
                                                    </>
                                                )}
                                            {candidate.isCv && (
                                                <>
                                                    <Col>
                                                        <label className="col-md-2 col-form-label" htmlFor="filePicker1"  style={{cursor:"pointer"}}>
                                                        <Link onClick={(e) => downloadFile(e, "cv")} style={{cursor: "pointer"}}>Download</Link>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <i className="fas fa-trash-alt" onClick={e => deleteFile(e, "cv")} style={{cursor: "pointer"}}></i>
                                                        </label>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-planindividual-input" className="col-md-1 col-form-label">Plan individual</Label>
                                            {!candidate.isPlan && (
                                                <>
                                                    <Col>
                                                        <label className="col-md-2 col-form-label" htmlFor="filePicker2"  style={{cursor:"pointer"}}>
                                                            Upload
                                                            <input id="filePicker2" type="file" name="file1" onChange={(e) => uploadFile(e, "plan")} style={{display: "none"}}></input>
                                                        </label>
                                                    </Col>
                                                </>
                                            )}
                                            {candidate.isPlan && (
                                                <>
                                                    <Col>
                                                        <label className="col-md-2 col-form-label" htmlFor="filePicker2"  style={{cursor:"pointer"}}>
                                                            <Link onClick={(e) => downloadFile(e, "plan")} style={{cursor: "pointer"}}>Download</Link>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <i className="fas fa-trash-alt" onClick={e => deleteFile(e, "plan")} style={{cursor: "pointer"}}></i>
                                                        </label>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                        <br/>
                                        <h4 className="card-title"><b>Istoric profil</b></h4>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-datasesiune-input" className="col-md-1 col-form-label">Data</Label>
                                            <Col md={3}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataObservatie"
                                                        className="form-control"
                                                        selected={formData.data === '' ? null : new Date(formData.data)}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Selecteaza data"
                                                        autoComplete="off"
                                                        minDate={new Date(candidate.dataIntrareGt === "" ? "" : candidate.dataIntrareGt)}
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-1 col-form-label">Observator</Label>
                                            <Col md={3}>
                                                <select className="form-select" name="watcherId" value={formData.watcherId} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza Observator</option>
                                                    {watchers.map((watcher) => <option value={watcher.id}>{watcher.nume + " " + watcher.prenume}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label className="col-md-1 col-form-label" htmlFor="example-observatii-input">Observatii</Label>
                                            <Col md={11}>
                                                <Input
                                                    type="textarea"
                                                    name="observatii"
                                                    id="example-observatii-input"
                                                    maxLength="225"
                                                    rows="3"
                                                    placeholder="Scrie observatiile aici."
                                                    onChange={onChangeInput}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-upload-input" className="col-md-1 col-form-label">Upload</Label>
                                            <Col md={3}>
                                                <Input type="text" name="upload" id="example-upload-input" />
                                            </Col>
                                            <Col xs={5}></Col>
                                            <Col xs={3} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                >
                                                    Salveaza
                                                </Button>
                                            </Col>
                                        </Row>
                                        {/*<Row className="mb-3">*/}
                                        {/*    <Label htmlFor="example-endtime-input" className="col-md-1 col-form-label">Ora final</Label>*/}
                                        {/*    <Col md={3}>*/}
                                        {/*        <div style={{position: "relative"}}>*/}
                                        {/*            <DatePicker*/}
                                        {/*                onChange={(e) => setFormData({ ...formData, oraFinal: e })}*/}
                                        {/*                className="form-control"*/}
                                        {/*                selected={formData.oraFinal}*/}
                                        {/*                showTimeSelect*/}
                                        {/*                showTimeSelectOnly*/}
                                        {/*                // timeIntervals={15}*/}
                                        {/*                dateFormat="HH:mm"*/}
                                        {/*                timeFormat="HH:mm"*/}
                                        {/*                timeCaption="Time"*/}
                                        {/*                placeholderText="Selecteaza ora final"*/}
                                        {/*            />*/}
                                        {/*            <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>*/}
                                        {/*            <span>*/}
                                        {/*                <i className="far fa-clock"></i>*/}
                                        {/*            </span>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row className="mb-3">*/}
                                        {/*    <Label htmlFor="example-minute-input" className="col-md-1 col-form-label">Numar minute</Label>*/}
                                        {/*    <Col md={3}>*/}
                                        {/*        <Input type="text" name="nrMinute" readonly="readonly" value={formData.nrMinute} id="example-minute-input" />*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<FormGroup row className="mb-3">*/}
                                        {/*    <Label className="col-md-1 col-form-label">Mediator</Label>*/}
                                        {/*    <Col md={3}>*/}
                                        {/*        <select className="form-select" name="mediatorId" onChange={onChangeInput}>*/}
                                        {/*            <option value="" disabled selected hidden>Selecteaza Mediator</option>*/}
                                        {/*            {mediators.map((mediator) => <option value={mediator.id}>{mediator.nume + " " + mediator.prenume}</option>)}*/}
                                        {/*        </select>*/}
                                        {/*    </Col>*/}
                                        {/*</FormGroup>*/}
                                        {/*<Row className="mb-3">*/}
                                        {/*    <Label htmlFor="example-observatii-input" className="col-md-1 col-form-label">Observatii</Label>*/}
                                        {/*    <Col md={6}>*/}
                                        {/*        <Input type="text" name="observatii" placeholder="Scrie observatii" id="example-observatii-input" onChange={onChangeInput} />*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Col xs={9}></Col>*/}
                                        {/*    <Col xs={3} align="center">*/}
                                        {/*        <Button*/}
                                        {/*            color="dark"*/}
                                        {/*            className="waves-effect waves-light me-1"*/}
                                        {/*        >*/}
                                        {/*            Salveaza*/}
                                        {/*        </Button>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                    </form>
                                    <Row>
                                        <Col xs={12}>
                                            <Card>
                                                <CardBody>
                                                    <div className="table-rep-plugin">
                                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                            <Table id="tech-companies-1" striped bordered responsive>
                                                                <thead>
                                                                <tr>
                                                                    <th className="dimenisune-col">Data</th>
                                                                    <th className="dimenisune-col">Observator</th>
                                                                    <th className="dimenisune-col3">Observatii</th>
                                                                    <th className="dimenisune-col">Actiune</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {observations.map((observation) => {
                                                                    return (
                                                                        <tr key={observation.id}>
                                                                            <td>{new Date(observation.data).toLocaleDateString('ro-RO', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                                                                            <td>{observation.watcher.nume + " " + observation.watcher.prenume}</td>
                                                                            <td>{observation.observatii}</td>
                                                                            <td>Edit/Delete</td>
                                                                        </tr>
                                                                    )
                                                                })}

                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProfilDetaliat;
