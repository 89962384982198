import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import TableComponent from "../Cursuri/TableComponent";
import axiosInstance from "../../helpers/AxiosHelper";
import Insert from "./Insert";

const PlanuriAfaceri = () => {
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/candidate/all').then((response) => {
            setCandidates(response.data);
        })
    }, []);

    function tempFunc(clickedRow) { // Recreez form data din cum arata randul in tabel
        console.log(clickedRow)
        // let newFormData = clickedRow?{ ...clickedRow, cursId: `${clickedRow.coursePrv.id}`, furnizorId: `${clickedRow.provider.id}`,dataStart: new Date(clickedRow.dataStart), dataFinal: new Date(clickedRow.dataFinal) }:emptyForm;
        // setFormData(newFormData)
    }


    return (
        <React.Fragment>
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <Card>
                                <CardBody>

                                    {candidates.length === 0 ? (
                                            <div align="center">
                                                <Spinner className="me-2" color="primary" />
                                                <p className="text-muted">Se conecteaza la baza de date...</p>
                                            </div>
                                        ) : <>
                                        <Row>
                                            <TableComponent fnc={tempFunc} receivedData={candidates} tableHeadings={["Nume", "Prenume", "Plan Afaceri"]} tableType={"PlanuriAfaceri"} setReceivedData={setCandidates}/>
                                        </Row>
                                    </>}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PlanuriAfaceri;
