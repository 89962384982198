import axios from 'axios'
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const baseURL = process.env.REACT_APP_BASE_URL
const headers = {} //by def appjson

if (localStorage.token) { headers.Authorization = `Bearer ${localStorage.token}` }

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers
});

// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         if (error.response.status === 403) {
//             // handle 403 error
//         }
//         return Promise.reject(error);
//     }
// );


export default axiosInstance;

// Usage :
// import AxiosInstance from 'axios-helper';
// axiosInstance.post('/home')
//     .then(res=>console.log(res))
//     .catch(err=>console.log(err))
