import React, {useState} from 'react';
import {Button, Col, Input, Label, Row} from "reactstrap";
import axiosInstance from "../../helpers/AxiosHelper";
import toastr from "toastr";

const NomenclatorFurnizor = () => {

    const [furnizorData, setFurnizorData] = useState({
        nume: '',
        cui: ''
    })

    async function handleFurnizorSubmit(e) {
        e.preventDefault()
        try {
            await axiosInstance.post('/api/provider/add', furnizorData,{
                headers: {"Content-Type": "application/json"}
            }).then(() => toastr.success(`Furnizorul a fost adaugat!`, ""))
                .catch(() => toastr.error(`Furnizorul nu a fost adaugat!`, ""))

        } catch(error) {
            console.log(error)
        }
        setFurnizorData({
            ...furnizorData,
            nume: '',
            cui: '',
        });
    }

    function onChangeInput(e) {
        setFurnizorData({...furnizorData, [e.target.name]: e.target.value})

    }

    return (
        <form onSubmit={handleFurnizorSubmit}>
            <h4 className="card-title"><b>Furnizor</b></h4>
            <Row className="mb-3">
                <Label htmlFor="example-nume-input" className="col-md-2 col-form-label">Nume Furnizor</Label>
                <Col md={10}>
                    <Input type="text" name="nume" value={furnizorData.nume} placeholder="Introdu Nume Furnizor" id="example-nume-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label htmlFor="example-cui-input" className="col-md-2 col-form-label">CUI</Label>
                <Col md={10}>
                    <Input type="text" name="cui" value={furnizorData.cui} placeholder="Introdu CUI" id="example-cui-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row>
                <Col xs={9}></Col>
                <Col xs={3} align="center">
                    <Button
                        color="dark"
                        className="waves-effect waves-light me-1"
                    >
                        Adauga Furnizor
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default NomenclatorFurnizor;
