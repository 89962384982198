import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import CursuriBar from "./CursuriBar";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axiosInstance from "../../helpers/AxiosHelper";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TableComponent from './TableComponent';
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";

const emptyForm = {
    id: '',
    locatie: '',
    dataStart: '',
    dataFinal: '',
    nrLocuri: '',
    furnizorId: '',
    cursId: '',
}
function DefaultFormField(props) {
    return (
        <Row className="mb-3">
            <Label className="col-md-2 col-form-label">{props.label}</Label>
            <Col md={4}>{props.children}</Col>
        </Row>
    )
}

function isNotFullyFilled(form) {
    let result = false;
    for (const key in form) {
        if (key === 'id') continue
        if (form[key] === '') result = true;
    }
    return result
}
const Sesiuni = () => {
    const [courses, setCourses] = useState([]);
    const [providers, setProviders] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [formData, setFormData] = useState(emptyForm);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handlePut = (e) => {
        e.preventDefault()
        try {
            axiosInstance.put('/api/session/update', formData, {
                params: {
                    sessionId: formData.id,
                    providerId: formData.furnizorId,
                    courseId: formData.cursId
                },
            }).then(res => {
                if (!(res.status === 200)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                toastr.success(`Sesiunea a fost actualizata!`, "")
                const response = res.data;
                console.log(response)
                setFormData(emptyForm);
                getAllSessions();
            }).catch(() => toastr.error(`Sesiunea nu a fost actualizata!`, ""))
        } catch (error) {
            console.log(error)
        }

    };
    // const handleDelete = (e) => { console.log('Delete Button Pressed') };

    if (successConfirm) {
        console.log("intra in stergere")
        if (formData.empty) {
            try {
                axiosInstance.delete('/api/session/delete/id', {
                    params: {
                        id: formData.id
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    setFormData(emptyForm);
                    getAllSessions();
                })
            } catch (error) {
                console.log(error)
            }
        } else {
            toastr.warning(`Sesiunea nu poate fi stearsa! Exista candidati alocati!`, "")
        }
        setSuccessConfirm(false)
    }

    function handleDelete(e) {
        e.preventDefault()
        console.log(formData.empty)
        setIsAlert(true);
        // if (formData.empty) {
        //     try {
        //         axiosInstance.delete('/api/session/delete/id', {
        //             params: {
        //                 id: formData.id
        //             },
        //         }).then(res => {
        //             if (!(res.status === 200)) {
        //                 throw new Error(`Error! status: ${res.status}`);
        //             }
        //             const response = res.data;
        //             console.log(response)
        //             setFormData(emptyForm);
        //             getAllSessions();
        //         })
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }

    }

    const breadcrumbItems = [
        { title: "Tables", link: "#" },
        { title: "Responsive Table", link: "#" },
    ];

    useEffect(() => {
        async function getData() {
            await axiosInstance.get('/api/course/all')
                .then(r => setCourses(r.data))
            await axiosInstance.get('/api/provider/all')
                .then(r => setProviders(r.data))
        }
        getAllSessions();
        getData();
    }, []);

    async function getAllSessions() {
        await axiosInstance.get('/api/session/all')
            .then(r => setSessions(r.data))
    }

    async function handlePost(e) {
        e.preventDefault();
        console.log(formData);
        try {
            await axiosInstance.post('/api/session/add', formData, {
                headers: { "Content-Type": "application/json" },
                params: {
                    cursId: formData.cursId,
                    furnizorId: formData.furnizorId,
                }
            })
            getAllSessions();
            setFormData(emptyForm);
        } catch (error) {
            console.log(error)
        }
    }
    function tempFunc(clickedRow) { // Recreez form data din cum arata randul in tabel
        // console.log(clickedRow)
        let newFormData = clickedRow?{ ...clickedRow, cursId: `${clickedRow.coursePrv.id}`, furnizorId: `${clickedRow.provider.id}`,dataStart: new Date(clickedRow.dataStart), dataFinal: new Date(clickedRow.dataFinal) }:emptyForm;
        setFormData(newFormData)
    }

    function getCursDenumire() {
        const course = courses.find((course) => course.id === formData.cursId);
        if (course === undefined) return "";
        return course.denumire
    }

    const handleDateChange = (event, name) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        setFormData({ ...formData, [name]: formattedDate });
    };

    return (
        <React.Fragment>
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{ paddingTop: "0px" }}>
                <Container fluid>
                    <CursuriBar page="Sesiuni" />
                    {/*<Breadcrumbs title="Adaugare / Editare cursuri" breadcrumbItems={breadcrumbItems} />*/}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Breadcrumbs title="Sesiune Noua" breadcrumbItems={breadcrumbItems} />
                                    {/*<Link to="/formular-candidati"><h4 className="card-title">+Adauga</h4></Link>*/}
                                    {/*<p className="card-title-desc">This is an experimental awesome solution for responsive tables with complex data.</p>*/}
                                    <form>
                                        <DefaultFormField label="Selectie Tip Curs">
                                            <select className="form-select" name="cursId" value={formData.cursId} onChange={handleChange}>
                                                <option value="" disabled selected hidden>Selecteaza curs</option>
                                                {courses.map((item) => <option key={item.id} value={item.id}>{item.denumire}</option>)}
                                            </select>
                                        </DefaultFormField>
                                        <DefaultFormField label="Selectie Furnizor*">
                                            <select className="form-select" name="furnizorId" value={formData.furnizorId} onChange={handleChange} >
                                                <option value="" disabled selected hidden>Selecteaza furnizor</option>
                                                {providers.map((item) => <option key={item.id} value={item.id}>{item.nume}</option>)}
                                            </select>
                                        </DefaultFormField>
                                        <DefaultFormField label="Locatie*">
                                            <Input type="text" name="locatie" placeholder="" id="example-locatie-input" value={formData.locatie} onChange={handleChange} disabled={formData.furnizorId === ''} />
                                        </DefaultFormField>
                                        <DefaultFormField label="Data Start">
                                            <DatePicker
                                                name="dataStart"
                                                className="form-control"
                                                selected={formData.dataStart === '' ? null : new Date(formData.dataStart)}
                                                onChange={(event) => handleDateChange(event, "dataStart")}
                                                dateFormat="dd-MM-yyyy"
                                                disabled={formData.locatie === ''}
                                                autoComplete="off"
                                            />

                                        </DefaultFormField>
                                        <DefaultFormField label="Data Final">
                                            <DatePicker
                                                name="dataFinal"
                                                className="form-control"
                                                selected={formData.dataFinal === '' ? null : new Date(formData.dataFinal)}
                                                onChange={(event) => handleDateChange(event, "dataFinal")}
                                                dateFormat="dd-MM-yyyy"
                                                disabled={formData.dataStart === ''}
                                                autoComplete="off"
                                                minDate={new Date(formData.dataStart === "" ? "" : formData.dataStart)}
                                            />
                                        </DefaultFormField>
                                        <DefaultFormField label="Nr Locuri">
                                            <Input type="text" name="nrLocuri" placeholder="" id="example-nrLocuri-input" onChange={handleChange} value={formData.nrLocuri} disabled={formData.dataFinal === ''} />
                                        </DefaultFormField>

                                        <Row className="mb-3">
                                            <Col xs={7}></Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                    type="submit"
                                                    onClick={(e) => {setFormData(emptyForm);e.preventDefault();}}
                                                >
                                                    RESET
                                                </Button>
                                            </Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                    type="submit"
                                                    disabled={isNotFullyFilled(formData) || formData.id !== ''}
                                                    onClick={handlePost}
                                                >
                                                    ADAUGA
                                                </Button>
                                            </Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                    type="submit"
                                                    disabled={isNotFullyFilled(formData) && formData.id === ''}
                                                    onClick={handlePut}
                                                >
                                                    ACTUALIZEAZA
                                                </Button>
                                            </Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                    type="submit"
                                                    disabled={isNotFullyFilled(formData) && formData.id === ''}
                                                    onClick={handleDelete}
                                                >
                                                    STERGE
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    <TableComponent fnc={tempFunc} receivedData={sessions} tableHeadings={["Id", "Curs", "Furnizor", "De la data", "La Data", "Locatie", "Locuri"]} tableType={"Sesiuni"}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Sesiuni;
